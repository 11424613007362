// src/Components/LoadingSpinner.js
import React from 'react';

export const SmallSpiner = (props) => {
  const color = props.color || "border-blue-500";
  return (<div className="flex items-center justify-center">
    <div className={"animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 " + color}></div>
  </div>
  );
}

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
};

export default LoadingSpinner;
