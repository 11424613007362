// src/Components/Slider/PhotoSlider.js
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { FaImages } from "react-icons/fa";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import BackBtn from '../Layout/BackBtn';

const PhotoSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(1);
    const totalSlides = 30;

    return (
        <div className='relative'>
          
            <div className="flex items-center absolute top-2 left-2 bg-black bg-opacity-80 text-white px-2 py-1 rounded z-50">
                <BackBtn class="text-white dark:text-white" />
            </div>

            <Swiper
                modules={[Pagination]}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true, type: 'progressbar' }}
                loop={true}
                onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex + 1)}
            >
                {Array.from({ length: 30 }).map((_, i) => <SwiperSlide key={i}>
                    <img src="https://placehold.co/600x400" alt="Slide 1" />
                </SwiperSlide>
                )}
            </Swiper>
            <div className="flex items-center absolute bottom-2 left-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded z-50">
                <FaImages className='inline-block mr-2' />
                <span>{currentSlide} / {totalSlides}</span>
            </div>

        </div>
    );
};

export default PhotoSlider;
