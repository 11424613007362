import { useCallback, useEffect, useState } from 'react';
import { useTelegram } from '@/Hooks/useTelegram';
import LoadingSpinner from '@/Components/Layout/LoadingSpinner';
import useUserStore from '@/Store/useUserStore';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Импорт стилей
import { useNavigate, useLocation } from 'react-router-dom';


const Layout = ({ children }) => {
    const { tg } = useTelegram();
    const { user, verifyUser, error, loading, setUser } = useUserStore();
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();

    const tgBackBtnClick = useCallback(() => {
        console.log("tgBackBtnClick: ", pathname)
        if (window.history?.length && window.history.length > 1) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }, []);
    useEffect(() => {
        tg.ready();
    }, [tg]);

    useEffect(() => {

        if (user) {
            setUser(user);
        } else {
            verifyUser(process.env.REACT_APP_ENVIRONMENT === 'dev' ? process.env.REACT_APP_API_TOKEN : tg.initData);
        }

    }, [user, tg]);

    useEffect(() => {
        tg.onEvent('backButtonClicked', tgBackBtnClick)

        if (pathname !== '/') {
            tg.BackButton.show();
        } else {
            tg.BackButton.hide();
        }

        return () => {
            tg.offEvent('backButtonClicked', tgBackBtnClick)
        }
    }, [pathname, tg]);

    if (loading) {
        return <div className='tg_bg'><LoadingSpinner /></div>;
    }

    if (!user || error) {
        return <div className="min-h-screen flex items-center justify-center tg_bg flex-col">
            <h1 className="text-2xl font-bold tg_text">Ви не авторизовані!</h1>
            <p className="text-center text-lg tg_text_hint w-3/4">Перезапустіть застосунок або зверніться до адміністратора.</p>
        </div>
    }

    return <div className="App tg_bg">
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
        />
        {children}
    </div>
}

export default Layout;