const tg = window.Telegram.WebApp;

export const useTelegram = () => {
    const onClose = () => {
        tg.close();
    };

    const onToggleButtons = (buttons) => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.show();
        }else {
            tg.MainButton.hide();
        }
    };

    return { 
        tg,
        user: tg.initDataUnsafe.user,
        onClose,
        onToggleButtons
     };
};
