

export const fuelList = [
    { label: "Бензин", value: "Бензин" },
    { label: "Дизель", value: "Дизель" },
    { label: "Газ", value: "Газ" },
    { label: "Гібрид", value: "Гібрид" },
    { label: "Електро", value: "Електро" },
    { label: "Газ / Бензин", value: "Газ / Бензин" },
    { label: "Інше", value: "Інше" },
];

export const gearboxList = [
    { label: "Механіка", value: "Механіка" },
    { label: "Автомат", value: "Автомат" },
    { label: "Типтронік", value: "Типтронік" },
    { label: "Робот", value: "Робот" },
    { label: "Варіатор", value: "Варіатор" },
    { label: "Інше", value: "Інше" },
];

export const automotiveDriveList = [
    { label: "Передній", value: "Передній" },
    { label: "Задній", value: "Задній" },
    { label: "Повний", value: "Повний" },
    { label: "Інше", value: "Інше" },
];

export const postingStatusList = ["не запощено", "запощено", "потребує оновлення"];
export const statusList = ["в очікуванні", "продано", "активно"];

export const statusIcons = {
    "в очікуванні": "🟡",
    "продано": "🟢",
    "активно": "🔵",
    "відмінено": "🔴",
};

export const filterTypes = {
    price_min: 'number',
    price_max: 'number',
    car_year_min: 'number',
    car_year_max: 'number',
    motor_min: 'number',
    motor_max: 'number',
    mileage_min: 'number',
    mileage_max: 'number',
    drive_type: 'string',
    location: 'string',
    fuel: 'string',
    gearbox: 'string',
};