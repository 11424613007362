// CarCard.js
import React, { useEffect, useState, useRef } from 'react';
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { PiEngineLight } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { convertToUAH, convertToUSD, formatCurrency } from '@/Utils/currency';
import { formatNumber } from '@/Utils/numberFormat';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual, Zoom } from 'swiper/modules';
import { statusIcons } from "@/Utils/optionsTypes";
import useUserCarStore from "@/Store/useUserCarStore";
import { useTelegram } from '@/Hooks/useTelegram';
import { BasicButton, HapticIconButton, HapticButton } from '../Buttons/Button';

const CarCard = ({ car }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    const [convertedPrice, setConvertedPrice] = useState(null);
    const maxPhotos = 9;
    const photos = Array.from({ length: maxPhotos }, (_, i) => i + 1).filter((_, i) => i < 8);
    const callToActionSlide = (
        <SwiperSlide key="cta">
            <div className="flex flex-col items-center justify-center h-48 bg-[--tg-theme-section-separator-color] rounded-t-lg">
                <p className="text-lg font-bold text-center mb-2 tg_text">Перейти до оголошення</p>
                <Link to={`/car/${car.id}`} className="tg_link py-2 px-4 rounded">
                    Детальніше
                </Link>
            </div>
        </SwiperSlide>
    );

    useEffect(() => {
        const fetchConvertedPrice = async () => {
            let convertedPrice = null;
            if (car.currency === 'USD' || car.currency === 'EUR') {
                convertedPrice = await convertToUAH(car.price, car.currency);
            } else if (car.currency === 'UAH') {
                convertedPrice = await convertToUSD(car.price);
            }
            setConvertedPrice(convertedPrice?.toFixed(2));
        };
        fetchConvertedPrice();
    }, [car.currency, car.price]);

    return (
        <motion.div
            ref={ref}
            className="tg_bg_secondary rounded-lg shadow-md mb-4 tg_text"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 1 }}
        >
            <Swiper
                modules={[Pagination, Virtual, Zoom]}
                pagination={{ clickable: true }}
                className="rounded-t-lg"
                lazy
                virtual
                zoom
            >
                {photos.map((photo, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        <div className="swiper-zoom-container">

                            <img
                                src={'https://via.placeholder.com/400x300'}
                                alt={`Car ${index + 1}`}
                                loading="lazy"
                                className="w-full h-48 object-cover rounded-t-lg"
                            />
                        </div>
                    </SwiperSlide>
                ))}
                {callToActionSlide}
            </Swiper>
            <div className="p-2">
                <div className="flex justify-between mb-2 flex-col">
                    <h2 className="text-lg font-semibold tg_text">{car.make} {car.model} {car.year}</h2>
                    <div className="flex items-center gap-2">
                        <span className="text-green-700 text-lg font-semibold dark:text-green-500 dark:text-lg dark:font-semibold">
                            {formatCurrency(car.price, car.currency)}
                        </span>
                        {car.currency === 'USD' || car.currency === 'EUR' ? (
                            <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'UAH')}</span>
                        ) : (
                            <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'USD')}</span>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-2 tg_text text-sm gap-y-4 dark:gap-y-4">
                    <div className="flex items-center gap-2">
                        <SlSpeedometer className="text-xl" />
                        <span>{formatNumber(car.mileage)} тис. км</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <IoLocationOutline className="text-xl" />
                        <span>{car.location}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <PiEngineLight className="text-xl" />
                        <span>{car.fuel}</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <TbManualGearbox className="text-xl" />
                        <span>{car.gearbox}</span>
                    </div>
                </div>
                <div className="flex justify-between items-center mt-4 text-sm tg_text_hint">
                    <div className="flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 max-w-1/2">
                        <span className="mr-2 bg-blue-500 px-1 dark:bg-blue-800 text-white">UA</span>
                        <span className="pr-2 truncate">{car.vincode}</span>
                    </div>
                    <span className="border-2 px-2 border-blue-500 rounded-md dark:border-blue-800">{car.vincode}</span>
                </div>
                <div className="mt-2 text-left tg_text_hint text-sm flex items-center justify-between">
                    <p>{new Date(car.created_at).toLocaleDateString()}</p>
                </div>
            </div>
        </motion.div>
    );
};

export const CarCardPrivat = ({ car }) => {
    const { removeAdsItem } = useUserCarStore();
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const { tg } = useTelegram();
    const menuRef = useRef(null);

    const handleDropdownToggle = () => {
        setShowDropdown(!showDropdown);
    };

    const handleEdit = () => {
        // TODO: Implement edit functionality
    };

    const handleDelete = async () => {
        // TODO: Implement delete functionality
        const [err, res] = await removeAdsItem(car.id);

        if (err) {
            console.log('Error: ', err);
            tg?.HapticFeedback?.notificationOccurred('error');
            return;
        }

        if (res) {
            console.log('Deleted: ', res);
            tg?.HapticFeedback?.notificationOccurred('success');
        }
    };

    useEffect(() => {
        // Close dropdown when clicking outside
        if (menuRef.current) {
            const handleClickOutside = (event) => {
                if (menuRef.current && !menuRef.current.contains(event.target)) {
                    setShowDropdown(false);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, []);

    return (
        <div className='tg_bg_secondary rounded-lg shadow-md mb-4 tg_text'>
            <div className='flex justify-between p-2 items-center'>
                <div className="">{statusIcons[car.status]} {car.status}</div>

                <div className="relative " ref={menuRef}>
                    <HapticIconButton
                        onClick={handleDropdownToggle}
                        className="p-2 rounded-md ml-auto flex gap-2 items-center justify-end tg_btn_color tg_btn_text"
                        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>}  // Передаємо іконку

                    >
                        Меню
                    </HapticIconButton>
                    {showDropdown && (
                        <motion.div
                            className="absolute z-10 right-0 mt-2 w-auto bg-white rounded-md shadow-lg"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.2 }}
                        >
                            <div className="absolute z-10 right-0 mt-2 w-auto bg-white rounded-md shadow-lg">
                                <HapticIconButton
                                    onClick={handleEdit}
                                    className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center gap-2"
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                    </svg>}
                                >
                                    Редагувати
                                </HapticIconButton>
                                <HapticIconButton
                                    onClick={() => setShowModal(true)}
                                    className="block w-full px-4 py-2 text-sm text-red-700 hover:bg-red-100 hover:text-red-900 flex items-center gap-2"
                                    icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>}
                                >
                                    Видалити
                                </HapticIconButton>
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>
            <CarCard car={car} />

            {showModal && (<motion.div
                className="fixed inset-0 flex items-center justify-center z-50"
                initial={{ opacity: 0 }}      // Початкова непрозорість
                animate={{ opacity: 1 }}      // Кінцева непрозорість
                exit={{ opacity: 0 }}         // Анімація зникнення
                transition={{ duration: 0.3 }}
            >
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="tg_bg p-6 rounded-lg shadow-lg">
                        <p>Ви впевнені, що хочете видалити оголошення?</p>
                        <div className="flex justify-end gap-4 mt-4">
                            <HapticButton
                                onClick={() => setShowModal(false)} // Закрити модальне вікно без дії
                                className="px-4 py-2 tg_btn_color tg_btn_text rounded"
                                hapticFeedback="light" // Відтворити вібрацію
                            >
                                Скасувати
                            </HapticButton>
                            <HapticButton
                                onClick={() => {
                                    handleDelete(); // Видалити оголошення
                                    setShowModal(false); // Закрити модальне вікно
                                }}
                                hapticFeedback="light" // Відтворити вібрацію
                                className="px-4 py-2 bg-red-600 text-white rounded"
                            >
                                Видалити
                            </HapticButton>
                        </div>
                    </div>
                </div>
                <div className="fixed inset-0 bg-black opacity-50 z-10"></div> {/* Background overlay */}
            </motion.div>)}

        </div>
    );
};

export default CarCard;
