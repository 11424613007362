import React, { useRef, useCallback, useState, useEffect } from "react";
import { FaUserCircle, FaFilter, FaSearch } from 'react-icons/fa';
import { Link } from "react-router-dom";
import useCarStore from '@/Store/useCarStore';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { MyInput, ForwardRefInput, SelectInput, CityAutoCompleteInput, CitySearchInput } from "../Form/Fields";
import { filterSchema } from "@/Utils/validationSchema";
import { useFormik } from "formik";
import { fuelList, gearboxList, automotiveDriveList, filterTypes } from "@/Utils/optionsTypes";
import { useTelegram } from "@/Hooks/useTelegram";
// import { SlSpeedometer } from "react-icons/sl";
// import { IoLocationOutline } from "react-icons/io5";
// import { PiEngineLight } from "react-icons/pi";
// import { TbManualGearbox } from "react-icons/tb";
// import { FaOilCan } from "react-icons/fa";
// import { ReactComponent as CarChassis } from "@/Utils/CarChassis.svg";
// import { validationSchema } from "@/Utils/validationSchema";

const digitSetter = (value) => {
    // Заменяем запятую на точку
    value = value.replace(',', '.');
    // Удаляем ведущие нули, если их больше одного и они не перед точкой
    if (/^0[0-9]+/.test(value)) {
        value = value.replace(/^0+/, '');
    }
    // Проверяем, что введенное значение - это корректное число или пустая строка
    const isNumeric = /^-?\d*(\.\d*)?$/.test(value);
    if (isNumeric || value === '') {
        return value;
    }

    return null;
}

const Header = () => {
    const { tg } = useTelegram();
    const { itemsPerPage, searchQuery, changeSearchQuery, fetchCarAds, setSrchBtnClicked, filters, setFilters, resetFilters } = useCarStore();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const searchInputRef = useRef(null);
    const cityInputRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            price_min: filters.price_min || '',
            price_max: filters.price_max || '',
            car_year_min: filters.car_year_min || '',
            car_year_max: filters.car_year_max || '',
            motor_min: filters.motor_min || '',
            motor_max: filters.motor_max || '',
            mileage_min: filters.mileage_min || '',
            mileage_max: filters.mileage_max || '',
            drive_type: filters.drive_type || '',
            location: filters.location || '',
            fuel: filters.fuel || '',
            gearbox: filters.gearbox || '',
        },
        validationSchema: filterSchema,
        onSubmit: (values) => {
            // Обработка значений
            const processedValues = Object.keys(values).reduce((acc, key) => {
                if (filterTypes[key] === 'number') {
                    acc[key] = values[key] !== '' ? Number(values[key]) : null;
                } else {
                    acc[key] = values[key];
                }
                return acc;
            }, {});

            console.log('Значения фильтров:', processedValues);
            setFilters(processedValues);
            // console.log('Значения фильтров:', filters);
            // setFilters(values);
        },
    });

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


    const handleMainButtonClick = useCallback(() => {
        fetchCarAds(1, itemsPerPage, searchQuery);
        setSrchBtnClicked(true); // Устанавливаем, что кнопка была нажата
        toggleDrawer(); // Закрываем боковое меню
        // tg.MainButton.hide();   // Скрываем кнопку после нажатия
    }, [fetchCarAds, itemsPerPage, searchQuery, formik.values]);

    const applyFilters = () => {
        formik.handleSubmit();
        setFilters(formik.values); // Обновляем filters перед вызовом handleMainButtonClick
        handleMainButtonClick(); // Вызываем функцию после обновления фильтров
        tg?.HapticFeedback?.impactOccurred('light');
    }

    // Функция для изменения searchQuery и показа кнопки
    const handleInputChange = (value) => {
        changeSearchQuery(value);
        setSrchBtnClicked(false); // Сбрасываем состояние кнопки при изменении ввода
    };


    const clearFilters = () => {
        handleInputChange(''); // Очищаем строку поиска
        resetFilters();
        formik.resetForm();
        handleMainButtonClick(); // Вызываем функцию после обновления фильтров
        tg?.HapticFeedback?.impactOccurred('light');
    }


    useEffect(() => {
        if (isDrawerOpen) {
            // body overflow hidden
            document.body.style.overflow = 'hidden';

            setTimeout(() => {
                if (searchInputRef.current) {
                    searchInputRef.current.focus();
                }
            }, 300); // Устанавливаем фокус с небольшой задержкой
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [isDrawerOpen]);

    return (<div>
        <div className="flex items-center justify-between mb-4 shadow-md px-4 py-2">
            <Link to="/profile">
                <FaUserCircle className="tg_accents text-2xl mr-4" />
            </Link>
            <ForwardRefInput
                type="search"
                name="search"
                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                value={searchQuery}
                onClick={toggleDrawer}
                readOnly
                placeholder="Porsche Macan"
                icon={<FaSearch className="tg_accents text-2xl" />}
            />
            <FaFilter className="tg_accents text-2xl ml-4" onClick={toggleDrawer} />
        </div>
        <Drawer
            open={isDrawerOpen}
            onClose={toggleDrawer}
            direction="right"
            className="drawer-class"
        >
            <div className="drawer-content flex flex-col h-screen">
                {/* header */}
                <div className="header flex items-baseline justify-between">
                    <div className="text-md py-2 tg_text">
                        Пошук та фільтрація
                    </div>
                    <div className=" ">
                        <button onClick={toggleDrawer} className=" red_text tg_btn p-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                </div>
                <hr className="mb-2" />

                <div className="flex flex-1 flex-col gap-2 overflow-auto mb-4">
                    <ForwardRefInput
                        type="search"
                        name="search"
                        className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                        value={searchQuery}
                        onChange={(value) => handleInputChange(value)}
                        placeholder="Porsche Macan"
                        onKeyDown={(e) => e.key === 'Enter' && handleMainButtonClick()}
                        icon={<FaSearch className="text-md" />}
                        error={null}
                        ref={searchInputRef} // Добавляем ref для автофокуса
                    />

                    <div className="flex gap-1 flex-wrap">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Ціна</span>
                            <span>$ (в дол.)</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint text-sm">Від</span>
                            <MyInput
                                type="text"
                                name="price_min"
                                className=" p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.price_min}
                                onChange={(value) => {
                                    const v = digitSetter(value);
                                    if (v !== null) {
                                        formik.setFieldValue("price_min", v);
                                    }
                                }}

                                placeholder="Мін ціна"
                                error={null}
                            />
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint text-sm">до</span>
                            <MyInput
                                type="text"
                                name="price_max"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.price_max}
                                onChange={(value) => {
                                    const v = digitSetter(value);
                                    if (v !== null) {
                                        formik.setFieldValue("price_max", v);
                                    }
                                }}
                                placeholder="Макс ціна"
                                error={null}
                            />
                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Рік</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint  text-sm">Від</span>
                            <MyInput
                                type="text"
                                name="car_year_min"
                                className=" p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.car_year_min}
                                onChange={(value) => formik.setFieldValue("car_year_min", Number(value))}
                                placeholder="Мін рік"
                                error={null}
                            />
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint  text-sm">до</span>
                            <MyInput
                                type="text"
                                name="car_year_max"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.car_year_max}
                                onChange={(value) => formik.setFieldValue("car_year_max", Number(value))}
                                placeholder="Макс рік"
                                error={formik.errors.car_year_max}
                            />
                        </div>
                    </div>


                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Об'єм двигуна</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1 flex-wrap">
                            <div className="flex items-center">
                                <span className="tg_text_hint text-sm">Від</span>
                                <MyInput
                                    type="text"
                                    name="motor"
                                    className=" p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                    value={formik.values.motor_min}
                                    onChange={(value) => {
                                        const v = digitSetter(value);
                                        if (v !== null) {
                                            formik.setFieldValue("motor_min", v);
                                        }
                                    }}
                                    placeholder="Мін об'єм"
                                    error={null}
                                />
                            </div>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1 flex-wrap">
                            <div className="flex items-center">
                                <span className="tg_text_hint  text-sm">до</span>
                                <MyInput
                                    type="text"
                                    name="motor_max"
                                    className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                    value={formik.values.motor_max}
                                    onChange={(value) => {
                                        const v = digitSetter(value);
                                        if (v !== null) {
                                            formik.setFieldValue("motor_max", v);
                                        }
                                    }}
                                    placeholder="Макс об'єм"
                                    error={null}
                                />
                            </div>

                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Пробіг</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint  text-sm">Від</span>
                            <MyInput
                                type="text"
                                name="mileage_min"
                                className=" p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.mileage_min}
                                onChange={(value) => {
                                    const v = digitSetter(value);
                                    if (v !== null) {
                                        formik.setFieldValue("mileage_min", v);
                                    }
                                }}
                                placeholder="Мін пробіг"
                                error={null}
                            />
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <span className="tg_text_hint  text-sm">до</span>
                            <MyInput
                                type="text"
                                name="mileage_max"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.mileage_max}
                                onChange={(value) => {
                                    const v = digitSetter(value);
                                    if (v !== null) {
                                        formik.setFieldValue("mileage_max", v);
                                    }
                                }}
                                placeholder="Макс пробіг"
                                error={null}
                            />
                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Тип приводу</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <SelectInput
                                name="drive_type"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.drive_type}
                                onChange={(value) => formik.setFieldValue("drive_type", value)}
                                options={automotiveDriveList}
                                error={formik.errors.drive_type}
                                data={automotiveDriveList}
                            />
                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Місто</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <CitySearchInput
                                type="search"
                                name="location"
                                className="w-full p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.location}
                                onChange={(value) => formik.setFieldValue("location", value)}
                                placeholder="Введите название города"
                                icon={<FaSearch className="text-md" />}
                                error={null}
                                ref={cityInputRef}
                            />

                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Паливо</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <SelectInput
                                name="fuel"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.fuel}
                                onChange={(value) => formik.setFieldValue("fuel", value)}
                                options={fuelList}
                                error={formik.errors.fuel}
                                data={fuelList}
                            />
                        </div>
                    </div>

                    <div className="flex gap-1 flex-wrap w-full">
                        {/* add title */}
                        <div className="text-md w-full tg_text flex items-baseline justify-between">
                            <span>Коробка передач</span>
                        </div>

                        <div className="flex items-baseline gap-1 flex-1">
                            <SelectInput
                                name="gearbox"
                                className="p-2 border border-gray-300 rounded outline-none focus:border-blue-500"
                                value={formik.values.gearbox}
                                onChange={(value) => formik.setFieldValue("gearbox", value)}
                                options={gearboxList}
                                error={formik.errors.gearbox}
                                data={gearboxList}
                            />
                        </div>
                    </div>

                </div>
                <div className="footer">
                    <div className="flex items-baseline mb-4">
                        <button onClick={applyFilters} className="tg_btn_color tg_btn_text tg_btn p-2 rounded w-1/2">Шукати</button>
                        <button onClick={clearFilters} className="border border-current red_text tg_btn ml-2 p-2 rounded w-1/2">Очистити</button>
                    </div>
                </div>
            </div >
        </Drawer >
    </div >
    );
};

export default Header;
