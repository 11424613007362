import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

const BackBtn = (props) => {
    const navigate = useNavigate();
    
    const goBack = () => {
        if(props.route)
            return navigate(props.route);

        navigate(-1)
    }

    return <button onClick={goBack} className={"flex items-center gap-1 font-bold rounded tg_link"}>  <IoIosArrowBack className="text-xl" />
        {props.text ?? "Назад"}</button>;
}

export default BackBtn;