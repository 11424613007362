import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: '/api_v2',
});

const util_api = axios.create({
    baseURL: '/api',
});
// Interceptor to add authorization header
api.interceptors.request.use((config) => {
    const tg = window.Telegram.WebApp; // Assuming tg is globally accessible
    const token = process.env.REACT_APP_ENVIRONMENT === 'dev' ? process.env.REACT_APP_API_TOKEN : tg.initData;
    if (tg && token) {
        config.headers['Authorization'] = `${token}`;
    }
    return config;
});

/**
 * Get car ads based on pagination, search query, and body parameters.
 * @param {number} page - The page number.
 * @param {number} limit - The number of ads per page.
 * @param {string} query - The search query.
 * @param {object} bodyParams - The body parameters for the search.
 * @returns {Promise} A promise that resolves to the car ads data.
 */
export const getCarAds = async (page = 1, limit = 10, query = '', bodyParams = {}) => {
    const response = await api.post('/search', bodyParams, {
        params: {
            offset: (page - 1) * limit,
            limit,
            query
        },
    });
    return response.data;
};

/**
 * Get a car ad by its ID.
 * @param {string} id - The ID of the car ad.
 * @returns {Promise} A promise that resolves to the car ad data.
 */
export const getCarAdById = async (id) => {
    const response = await api.get(`/car_ads/${id}`);
    return response.data;
};

/**
 * Create a new car ad.
 * @param {object} newAd - The new car ad object.
 * @returns {Promise} A promise that resolves to the created car ad data.
 */
export const createCarAd = async (newAd) => {
    try {
        const response = await api.post('/car_ads', newAd);
        const status = response.status;
        if (status >= 200 && status < 300) {
            toast.success('Ваше оголошення успішно додано і відправлено на модерацію, дякуємо!');
        }
        if (status >= 300) {
            toast.error('Сталася помилка, спробуйте пізніше або зверніться до адміністратора');
        }
        return { ...response.data, status };
    } catch (error) {
        const status = error.response.status;
        if (status >= 300) {
            toast.error('Сталася помилка, спробуйте пізніше або зверніться до адміністратора');
        }
        return { ...error.response.data, status };
    }
};

/**
 * Update a car ad by its ID.
 * @param {string} id - The ID of the car ad.
 * @param {object} updatedAd - The updated car ad object.
 * @returns {Promise} A promise that resolves to the updated car ad data.
 */
export const updateCarAd = async (id, updatedAd) => {
    const response = await api.put(`/car_ads/${id}`, updatedAd);
    return response.data;
};

/**
 * Delete a car ad by its ID.
 * @param {string} id - The ID of the car ad.
 * @returns {Promise} A promise that resolves to the deleted car ad data.
 */
export const deleteCarAd = async (id) => {
    const response = await api.delete(`/car_ads/${id}`);
    return response.data;
};

/**
 * Verify a user based on query parameters.
 * @param {string} paramsString - The query parameters as a string.
 * @returns {Promise} A promise that resolves to the verification data.
 */
export const verifyUser = async (paramsString) => {
    const response = await api.get('/verify-user?' + paramsString.trim());
    return response.data;
};

/**
 * Get car ads created by the current user based on pagination and search query.
 * @param {number} page - The page number.
 * @param {number} limit - The number of ads per page.
 * @param {string} query - The search query.
 * @param {string} status - The status of the car ad.
 * @returns {Promise} A promise that resolves to the user's car ads data.
 */
export const getUserCarAds = async (page = 1, limit = 10, query = '', status = '') => {
    const response = await api.get('/user/car_ads', {
        params: {
            offset: (page - 1) * limit,
            limit,
            query,
            status
        },
    });
    return response.data;
};

/**
 * Get suggestions based on a query.
 * @param {string} query - The search query.
 * @returns {Promise} A promise that resolves to the suggestions data.
 */
export const getSuggestions = async (query) => {
    const response = await api.get('/suggestions', {
        params: {
            query,
            limit: 30
        },
    });
    return response.data;
}

/**
 * Get city suggestions based on a query.
 * @param {string} query - The search query.
 * @returns {Promise} A promise that resolves to the city suggestions data.
 */
export const getCitySuggestions = async (query) => {
    const response = await util_api.get('/autocomplete-cities', {
        params: {
            q: query,
        },
    });
    return response.data;
};

/**
 * Get car brands suggestions based on a query.
 * @param {string} query - The search query.
 * @returns {Promise} A promise that resolves to the car brands suggestions data.
 */

export const editAds = async (id, data) => {
    const response = await api.put(`/car_ads/${id}`, data);
    return response.data;
}

/**
 * 
 * @param {number} id 
 * @returns {Promise}  A promise that resolves to the deleted car ad data.
 */
export const deleteAds = async (id) => {
    try {
        const response = await api.delete(`/car_ads/${id}`);
        const status = response.status;
        if (status >= 200 && status < 300) {
            toast.success('Оголошення успішно видалено');
        }

        if (status >= 300) {
            toast.error('Сталася помилка, спробуйте пізніше або зверніться до адміністратора');
        }

        return { ...response.data, status };
    } catch (error) {
        const status = error.response.status;
        if (status >= 300) {
            toast.error('Сталася помилка, спробуйте пізніше або зверніться до адміністратора');
        }
        return { ...error.response.data, status };
    }
}