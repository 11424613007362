import React, { useEffect, useState, useCallback } from "react";
import Header from '@/Components/Layout/Header';
import CarCard from '@/Components/Card/CarCard';
import { Link } from 'react-router-dom';
import useCarStore from '../Store/useCarStore';
import LoadingSpinner, { SmallSpiner } from "@/Components/Layout/LoadingSpinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from 'lodash';
import { useTelegram } from "@/Hooks/useTelegram";

const Home = () => {
    const { tg } = useTelegram();
    const { carAds, resetFilters, fetchCarAds, total, firstLoad, setFirstLoad, loading, error, page, itemsPerPage, changePage, searchQuery, changeSearchQuery, setLoading } = useCarStore();
    const [isFetching, setIsFetching] = useState(false);

    const clearFilters = () => {
        changeSearchQuery('');
        resetFilters();
        debouncedFetchCarAds();
    }

    const debouncedFetchCarAds = useCallback(
        debounce(() => {
            changePage(1);
            loadMoreCars()
        }, 500),
        []
    );

    useEffect(() => {
        console.log("searchQuery: ", searchQuery.length)
    }, [searchQuery])

    useEffect(() => {
        debouncedFetchCarAds();
        setFirstLoad(false);
        return () => {
            debouncedFetchCarAds.cancel();
            setLoading(false);
        }
    }, []);
    // Load more data when user scrolls down
    const loadMoreCars = async () => {

        if (isFetching || loading || (carAds.length >= total && page !== 1)) {
            return;
        }
        setIsFetching(true); // Set fetching state to prevent multiple calls

        try {
            await fetchCarAds(page, itemsPerPage, searchQuery);
            changePage(page + 1); // Increment page count in store
            tg?.HapticFeedback?.notificationOccurred('success');
        } catch (error) {
            console.log("error: ", error);
            tg?.HapticFeedback?.notificationOccurred('error');
        } finally {
            setIsFetching(false);
            setLoading(false);
        }

    };

    if (loading && firstLoad) return <LoadingSpinner />;

    if (error) return <div className="min-h-screen p-4 font-bold text-center tg_text flex items-center justify-center">
        Сталася помилка, спробуйте пізніше або зверніться до адміністратора
    </div>;

    return <div className="App tg_bg">
        <Header />
        {/* create ads btn */}
        <div className="flex justify-center">
            <Link to="/create" className="w-full mx-4 justify-center flex items-center gap-2 tg_btn_color tg_btn_text uppercase font-semibold px-4 py-4 items-center rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                Створити оголошення</Link>
        </div>
        <div className="min-h-screen p-4">

            {(!carAds || carAds.length === 0) &&
                !loading && !isFetching && !firstLoad
                && <div className="flex flex-col gap-4">
                    <h1 className="text-2xl font-bold text-center tg_text">Нічого не знайдено</h1>

                    <button onClick={clearFilters} className="tg_btn_color tg_btn_text uppercase font-semibold px-4 py-4 items-center rounded">Повернутися на початок</button>
                </div>}

            {carAds && carAds.length > 0 &&
                <InfiniteScroll
                    dataLength={carAds.length} // Current number of items
                    next={loadMoreCars} // Function to fetch more items
                    hasMore={carAds.length < total} // Whether there are more items to load
                    loader={<SmallSpiner />} // Component to show while loading
                    className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 !overflow-hidden"
                    endMessage={
                        <p style={{ textAlign: "center", padding: "20px" }}>
                            <b className="tg_text_hint">Ви переглянули всі наявні оголошення</b>
                        </p>
                    }
                >
                    {carAds.map((car) => (
                        <Link key={car.id} to={`/car/${car.id}`} className="hover:text-inherit hover:decoration-none">
                            <CarCard car={car} />
                        </Link>
                    ))}
                </InfiniteScroll>
            }
        </div>
    </div>
}

export default Home;