// store/useCarStore.js
import { create } from 'zustand'
import { devtools } from 'zustand/middleware';
import { deleteAds, getUserCarAds } from '@/Services/api';

const useUserCarStore = create(
  devtools((set, get) => ({
    carAds: [],
    searchQuery: '',
    total: 0,
    page: 1,
    status: '',
    itemsPerPage: 10,
    loading: false,
    error: null,
    srchBtnClicked: false,
    fetchCarAds: async (page, itemsPerPage, sq) => {
      set({ loading: true, error: null });
      try {

        if (sq !== get().searchQuery) {
          set({ page: 1 });
        }

        const data = await getUserCarAds(page, itemsPerPage, sq, get().status);

        set((state) => ({
          carAds: data.car_ads ? data.car_ads : [],
          total: data.total,
          loading: false,
        }));

      } catch (error) {
        set({ error: error.message, loading: false });
      } finally {
        set({ loading: false });
      }
    },
    changePage: (page) => set({ page }),
    changeSearchQuery: (searchQuery) => set({ searchQuery }),
    setSrchBtnClicked: (srchBtnClicked) => set({ srchBtnClicked }),
    setStatus: (status) => set({ status }),

    /**
     * 
     * @param {number} id 
     * @returns {Promise}  A promise that resolves to the deleted car ad data.
     */
    removeAdsItem: async (id) => {
      const res = await deleteAds(id);

      if (res.status >= 200 && res.status < 300) {
        set((state) => ({
          carAds: state.carAds.filter((item) => item.id !== id),
        }));

        return [null, res.data];
      }

      if (res.status >= 300) {
        console.log('error: ', res);
        return [res, null];
      }
    },
    // Другие функции для getCarAdById, createCarAd, updateCarAd, deleteCarAd
  })));

export default useUserCarStore;
