// store/useCarStore.js
import { create } from 'zustand'
import { devtools } from 'zustand/middleware';
import { getCarAds, getCarAdById, createCarAd, updateCarAd, deleteCarAd } from '@/Services/api';
import { filterTypes } from '@/Utils/optionsTypes';

const useCarStore = create(
  devtools((set, get) => ({
    firstLoad: true,
    carAds: [],
    searchQuery: '',
    total: 0,
    page: 1,
    itemsPerPage: 10,
    loading: false,
    error: null,
    srchBtnClicked: false,
    filters: {
      price_min: 0,
      price_max: 0,
      car_year_min: 0,
      car_year_max: 0,
      discounted_price_min: 0,
      discounted_price_max: 0,
      motor_min: 0.0,
      motor_max: 0.0,
      mileage_min: 0,
      mileage_max: 0,
      drive_type: '',
      location: '',
    },
    setFilters: (_filters) => set((state) => ({
      filters: {
        ...state.filters,
        ..._filters
      }
    })),
    setLoading: (loading) => set({ loading }),
    changePage: (page) => set({ page }),
    changeSearchQuery: (searchQuery) => set({ searchQuery }),
    setSrchBtnClicked: (srchBtnClicked) => set({ srchBtnClicked }),
    resetFilters: () => set({
      filters: {
        price_min: 0,
        price_max: 0,
        car_year_min: 0,
        car_year_max: 0,
        discounted_price_min: 0,
        discounted_price_max: 0,
        motor_min: 0.0,
        motor_max: 0.0,
        mileage_min: 0,
        mileage_max: 0,
        drive_type: '',
        location: '',
      },
      searchQuery: '',
    }),

    filterDefaultValues: (filters) => {
      const processedValues = Object.keys(filters).reduce((acc, key) => {
        let value = filters[key];

        // Приведение значения к нужному типу
        if (filterTypes[key] === 'number') {
          value = value !== '' ? Number(value) : null;
        }

        // Исключаем пустые значения, нули и т.д.
        if (value !== '' && value !== null && value !== undefined && value !== 0) {
          acc[key] = value;
        }

        return acc;
      }, {});

      console.log("processedValues: ", processedValues);
      return processedValues;
    },

    setFirstLoad: (firstLoad) => set({ firstLoad }),
    fetchCarAds: async (page, itemsPerPage, sq) => {
      set({ error: null });
      try {

        if ((sq !== get().searchQuery && sq >= 3) || (get().searchQuery !== '' && sq === '')) {
          set({ page: 1 });
        }

        // Получаем текущие фильтры из состояния
        const currentFilters = get().filters;

        // Фильтруем значения по умолчанию
        const filteredFilters = get().filterDefaultValues(currentFilters);

        const bodyParams = {
          ...filteredFilters
        };

        console.log("bodyParams: ", bodyParams)

        const data = await getCarAds(page, itemsPerPage, sq, bodyParams);
        const carAds = Array.isArray(data.car_ads) ? data.car_ads : [];

        if (page === 1) {
          set({ carAds: carAds, total: data.total });
        } else {
          set((state) => (
            state.carAds ? {
              carAds: state.carAds ? [...state.carAds, ...carAds] : carAds,
              total: data.total,

            } : { state }
          ));
        }
      } catch (error) {
        set({ error: error.message });
      }
    },
    // Другие функции для getCarAdById, createCarAd, updateCarAd, deleteCarAd
  })));

export default useCarStore;
