// services/currency.js
import axios from 'axios';

const apiURL = 'https://api.privatbank.ua/p24api/pubinfo?json&exchange&coursid=5';

export const getExchangeRates = async () => {
  try {
    // const response = await axios.get(apiURL);
    return [
      {
        "ccy": "EUR",
        "base_ccy": "UAH",
        "buy": "43.90000",
        "sale": "44.90000"
      },
      {
        "ccy": "USD",
        "base_ccy": "UAH",
        "buy": "40.65000",
        "sale": "41.25000"
      }
    ];
  } catch (error) {
    console.error('Failed to fetch exchange rates:', error);
    return [];
  }
};
