// userObject = {
//     CreatedAt: "2024-08-04T15:06:34.357935688Z",
//     Email: "",
//     FirstName: "Dariy",
//     ID: 2,
//     IsActive: true,
//     IsAdmin: false,
//     LastName: "Ratushniy",
//     PhoneNumber: "",
//     TelegramID: "274832370",
//     TelegramUsername: "DarikWaku",
//     UpdatedAt: "2024-08-04T15:06:34.357935688Z",
//     Username: "DarikWaku"
// }
import { create } from 'zustand'
import { devtools } from 'zustand/middleware';
import { verifyUser } from '@/Services/api';

const useUserStore = create(
    devtools((set) => ({
        user: null,
        setUser: (user) => set({ user }),
        logout: () => set({ user: null }),
        loading: true,
        error: null,
        verifyUser: async (tg) => {
            set({ loading: true, error: null });
            try {
                const res = await verifyUser(tg);
                set({ user: res, loading: false });
            } catch (error) {
                set({ error: error.message, loading: false });
            } finally {
                set({ loading: false });
            }
        }
    })));

export default useUserStore;