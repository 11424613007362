// utils/currency.js
import { getExchangeRates } from '@/Services/currency';


export const convertToUAH = async (amount, currency) => {
    const exchangeRates = await getExchangeRates();
    const rate = exchangeRates.find(rate => rate.ccy === currency);
    if (rate) {
        return amount * parseFloat(rate.buy);
    }

    return amount;
};

export const convertToUSD = async (amount) => {
    const exchangeRates = await getExchangeRates();

    const usdRate = exchangeRates.find(rate => rate.ccy === 'USD');
    if (usdRate) {
        return amount / parseFloat(usdRate.sale);
    }

    return amount;
};

export const formatCurrency = (amount = 0.0, currency = 'USD') => {
    return new Intl.NumberFormat('uk-UA', { style: 'currency', currency }).format(amount);
};
