import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PhotoSlider from "../Components/Card/PhotoSlider";
import LoadingSpinner from "@/Components/Layout/LoadingSpinner";
import { getCarAdById } from '@/Services/api';
import { SlSpeedometer } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { PiEngineLight } from "react-icons/pi";
import { TbManualGearbox } from "react-icons/tb";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { convertToUAH, convertToUSD, formatCurrency } from '@/Utils/currency';
import { formatNumber } from '@/Utils/numberFormat';

const CarPage = () => {
    const { id } = useParams();
    const [car, setCar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [convertedPrice, setConvertedPrice] = useState(null);

    useEffect(() => {
        const fetchConvertedPrice = async () => {
            let convertedPrice = null;
            if (car.currency === 'USD' || car.currency === 'EUR') {
                convertedPrice = await convertToUAH(car.price, car.currency);
            } else if (car.currency === 'UAH') {
                convertedPrice = await convertToUSD(car.price);
            }
            setConvertedPrice(convertedPrice?.toFixed(2));
        };

        if (car)
            fetchConvertedPrice();
    }, [car]);

    useEffect(() => {
        const fetchCarDetails = async () => {
            try {
                // Замініть цей URL на реальний шлях до вашого API
                const data = await getCarAdById(id)
                setCar(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCarDetails();
    }, [id]);

    if (loading) return <LoadingSpinner />;
    if (error) return <div>Помилка: {error}</div>;

    return (
        <div className="min-h-screen">
            <div>
                <PhotoSlider />
            </div>
            <div className="flex gap-4 p-2 flex-col">
                <div className="tg_section_bg rounded-lg p-2">
                    <div className="flex justify-between mb-2 flex-col">
                        <h2 className="text-lg font-bold tg_text">{car.make} {car.model} {car.year}</h2>
                        <div className="flex items-center gap-2">
                            <span className="text-green-700 text-lg font-semibold dark:text-green-500 dark:text-lg dark:font-semibold">
                                {formatCurrency(car.price, car.currency)}
                            </span>
                            {car.currency === 'USD' || car.currency === 'EUR' ? (
                                <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'UAH')}</span>
                            ) : (
                                <span className="text-sm tg_text_hint">~{formatCurrency(convertedPrice, 'USD')}</span>
                            )}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 tg_text text-sm gap-y-4 dark:gap-y-4 p-2">
                        <div className="flex items-center gap-2">
                            <SlSpeedometer className="text-xl" />
                            <span>{formatNumber(car.mileage)} тис. км</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <IoLocationOutline className="text-xl" />
                            <span>{car.location}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <PiEngineLight className="text-xl" />
                            <span>{car.fuel}</span>
                        </div>
                        <div className="flex items-center gap-2">
                            <TbManualGearbox className="text-xl" />
                            <span>{car.gearbox}</span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center mt-4 text-sm tg_text_hint ">
                        <div className="flex items-center border-2 border-blue-500 rounded-md dark:border-blue-800 max-w-1/2">
                            <span className="mr-2 bg-blue-500 px-1 dark:bg-blue-800 text-white">UA</span>
                            <span className="pr-2 truncate">{car.vincode}</span>
                        </div>
                        <span className="border-2 px-2 border-blue-500 rounded-md dark:border-blue-800">{car.vincode}</span>
                    </div>
                    <div className="mt-2 text-left tg_text_hint text-sm">
                        <p>{new Date(car.created_at).toLocaleDateString()}</p>
                    </div>
                </div>
                <div className="tg_section_bg rounded-lg tg_text p-2">
                    <h3 className="text-lg font-bold">Опис</h3>
                    <p>{car.description}</p>
                </div>
            </div>
        </div>
    );
};

export default CarPage;