import React, { useEffect, useCallback } from "react";
import { useTelegram } from "../Hooks/useTelegram";
import BackBtn from "../Components/Layout/BackBtn";
import { useNavigate } from "react-router-dom";
import useUserCarStore from "@/Store/useUserCarStore";
import LoadingSpinner from "@/Components/Layout/LoadingSpinner";
import { CarCardPrivat } from "@/Components/Card/CarCard";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { postingStatusList, statusList, statusIcons } from "@/Utils/optionsTypes";

const LinkLayout = ({ isLink = false, id, children }) => {

    if (!isLink) return <div>{children}</div>

    return <Link className="hover:text-inherit" to={`/car/${id}`}>{children}</Link>
}

const Profile = () => {
    const { carAds, fetchCarAds, total, loading, error, page, itemsPerPage, changePage, searchQuery, status, setStatus } = useUserCarStore();
    const { tg, user } = useTelegram();
    const navigate = useNavigate();

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Створити оголошення',
        });

        handleMainButtonClick();

        return () => {
            tg.MainButton.offClick(handleMainButtonClick);
            tg.MainButton.hide();
        };
    }, [tg.MainButton]);

    const handleMainButtonClick = useCallback(() => {
        tg.MainButton.show();
        tg.MainButton.onClick(() => navigate('/create'));
    }, []);

    const handlePageClick = (event) => {
        changePage(event.selected + 1);
    };

    useEffect(() => {
        fetchCarAds(page, itemsPerPage, searchQuery);
    }, [page, status]);

    if (loading) return <LoadingSpinner />;

    if (error) return <div className="min-h-screen p-4 font-bold text-center tg_text flex items-center justify-center">
        Сталася помилка, спробуйте пізніше або зверніться до адміністратора
    </div>;

    return <div className="min-h-screen p-4">
        <div className="flex items-center justify-between flex-wrap gap-2">
            <BackBtn route={"/"} />
            <h1 className="text-2xl font-bold tg_text">@{user?.username ?? "username"}</h1>

            <div className="flex items-center gap-2 w-full mb-2 w-full">
                <select className="tg_text tg_bg border border-gray-300 mx-auto w-full p-2 rounded" value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">УСІ</option>
                    {postingStatusList.map((status, index) => (
                        <option key={index} value={status} onClick={() => setStatus(status)}>{status.toUpperCase()}</option>
                    ))}
                </select>
            </div>
        </div>
        {total === 0 && <div className="fixed_and_centered text-center w-[80%] rounded-md shadow-md p-4 dark:bg-gray-900 dark:bg-opacity-80 ">
            {status === "" ? <p className="tg_text">
                У Вас ще немає оголошень з таким статусом.</p> : <p className="tg_text">
                У Вас ще немає оголошень.<br />
                Бажаєте створити оголошення?
            </p>}
        </div>}
        {total > 0 && <p className="text-center tg_text uppercase font-semibold text-xl mb-2">Ваші оголошення</p>}
        {carAds.map((car) => (
            <LinkLayout key={car.id} id={car.id} isLink={statusList[car.status] === "активно"}>
                <CarCardPrivat car={car} />
            </LinkLayout>
        ))}
        {carAds.length !== 0 &&
            <ReactPaginate
                previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(total / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                initialPage={page - 1}
                containerClassName={'pagination'}
                activeClassName={'active'}
                className="flex justify-center mt-4 items-center gap-1 pagination"
                pageClassName="flex items-center justify-center w-8 h-8 tg_text tg_border_white pagination__page"
            />
        }
    </div>
}

export default Profile;
