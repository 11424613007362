import React from "react";
import { useTelegram } from '@/Hooks/useTelegram';

export const BasicButton = ({ onClick, children, className = '' }) => {
    return (
        <button onClick={onClick} className={`p-2 rounded-md ${className}`}>
            {children}
        </button>
    );
};

export const IconButton = ({ onClick, icon, children, className = '' }) => {
    return (
        <BasicButton onClick={onClick} className={className}>
            {icon}
            {children}
        </BasicButton>
    );
};


export const HapticButton = ({ onClick, children, hapticFeedback = 'light', className = '' }) => {
    const { tg } = useTelegram();

    const handleClick = () => {
        if (tg?.HapticFeedback) {
            tg.HapticFeedback.impactOccurred(hapticFeedback);
        }
        if (onClick) onClick();
    };

    return (
        <BasicButton onClick={handleClick} className={className}>
            {children}
        </BasicButton>
    );
};


export const HapticIconButton = ({ hapticFeedback = 'light', onClick, icon, children, className = '' }) => {
    const { tg } = useTelegram();

    const handleClick = () => {
        if (tg?.HapticFeedback) {
            tg.HapticFeedback.impactOccurred(hapticFeedback);
        }
        if (onClick) onClick();
    };

    return (
        <BasicButton onClick={handleClick} className={className}>
            {icon}
            {children}
        </BasicButton>
    );
}